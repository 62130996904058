
import Page from "@/core-ui/Page.vue";
import Button from "@/core-ui/Button.vue";
import { defineComponent, ref } from "vue";
import ForgotPasswordModal from "@/components/login/ForgotPasswordModal.vue";
import LoginForm from "@/components/login/LoginForm.vue";
import WorkspaceForm from "@/components/login/WorkspaceForm.vue";

export default defineComponent({
    props: ["redirectTo", "workspace"],
    setup(props) {
        const showForgotPassword = ref(false);
        const workspaceRouter = ref(!!props.workspace);

        return {
            showForgotPassword,
            workspaceRouter,
        };
    },
    components: {
        Page,
        ForgotPasswordModal,
        LoginForm,
        WorkspaceForm,
        Button,
    },
});
