
import Card from "@/core-ui/Card.vue";
import BoxInput from "@/core-ui/forms/components/BoxInput.vue";
import Button from "@/core-ui/Button.vue";
import { useFormCtrl } from "@/core-ui/forms/compositions";
import { defineComponent, ref } from "vue";
import TextButton from "@/core-ui/TextButton.vue";

export default defineComponent({
    props: ["redirectTo"],
    emits: ["show-forgot-password"],

    setup(props, ctx) {
        const error = ref("");

        return {
            error,
            formCtrl: useFormCtrl({
                key: "login",
                action: async (v: any) => {
                    try {
                        error.value = "";
                    } catch (e) {
                        console.error(e);
                        const errMsg = "Invalid email or password";
                        error.value = errMsg;
                        return Promise.reject(errMsg);
                    }
                },
            }),
        };
    },
    components: {
        Card,
        BoxInput,
        Button,
        TextButton,
    },
});
