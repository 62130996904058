import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!
  const _directive_form_focus = _resolveDirective("form-focus")!

  return (_openBlock(), _createBlock(_component_Modal, {
    title: "Forgot Password",
    onCancel: _ctx.closeModal
  }, {
    body: _withCtx(() => [
      (_ctx.isCanResetPasswordByEmail)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.alertText)
          }, " Please enter your email address below and press send to receive a password reset link in your mailbox. ", 2))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(_ctx.$style.alertText)
          }, "Please ask the Run:AI administrator to reset your password.", 2))
    ]),
    buttons: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.buttons)
      }, [
        (_ctx.isCanResetPasswordByEmail)
          ? _withDirectives((_openBlock(), _createElementBlock("form", {
              key: 0,
              onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"])),
              class: _normalizeClass(_ctx.$style.forgotPassword)
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.inputField)
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.inputTitle)
                }, "E-mail:", 2),
                _createVNode(_component_TextInput, {
                  type: "email",
                  modelValue: _ctx.email,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                  required: ""
                }, null, 8, ["modelValue"])
              ], 2),
              _createVNode(_component_Button, {
                text: "Send",
                "button-type": "main",
                type: "submit",
                disabled: _ctx.loading,
                aid: "forgot-password-modal-submit-btn"
              }, null, 8, ["disabled"])
            ], 34)), [
              [_directive_form_focus]
            ])
          : _createCommentVNode("", true),
        _createVNode(_component_Button, {
          class: _normalizeClass(_ctx.$style.cancelButton),
          text: _ctx.isCanResetPasswordByEmail ? 'Cancel' : 'Ok',
          "button-type": "secondary",
          onClick: _ctx.closeModal,
          aid: "forgot-password-modal-close-btn"
        }, null, 8, ["class", "text", "onClick"])
      ], 2)
    ]),
    _: 1
  }, 8, ["onCancel"]))
}