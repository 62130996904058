
import Card from "@/core-ui/Card.vue";
import BoxInput from "@/core-ui/forms/components/BoxInput.vue";
import Button from "@/core-ui/Button.vue";
import { useFormCtrl } from "@/core-ui/forms/compositions";
import tenantStore from "@/stores/tenantStore";
import { getTenantDomain } from "@/helpers/is-production-env";
import { defineComponent, watchEffect } from "vue";

export default defineComponent({
    props: ["redirectTo"],
    setup() {
        const errorMsg = "We couldn’t find your workspace";

        const unfound = new Set();

        const isWorkspaceUrl = location.hostname.startsWith("workspace.");

        const ctrlForm = useFormCtrl({
            key: "oauthLogin",
            action: async (v: any) => {
                try {
                    window.location.replace(`${location.protocol}//${getTenantDomain(v.name)}/`);
                } catch (e) {
                    console.error(e);
                    unfound.add(v.name);
                    syncError();
                    return Promise.reject(e);
                }
            },
        });

        function syncError() {
            const ctrl = ctrlForm.children.get("name");
            if (ctrl?.value && unfound.has(ctrl?.value)) {
                ctrl.context.error = errorMsg;
            } else if (ctrl?.context.error) {
                ctrl.context.error = "";
            }
        }

        watchEffect(() => {
            syncError();
        });

        return { ctrlForm, isWorkspaceUrl };
    },
    components: {
        Card,
        BoxInput,
        Button,
        // TextButton,
    },
});
